const state = {
	activeId: 1,
	navList: [
		{
			id: 1,
			name: "批量打印",
			route: "/batchprint",
		},
		// {
		// 	id: 2,
		// 	name: "单个打印",
		// 	route: "/singleprint",
		// },
		{
			id: 3,
			name: "基础设置",
			route: "/setting",
		},
		// {
		//   id: 4,
		//   name: "宝贝简称",
		//   route: "/introduct",
		// },
		// {
		//   id: 5,
		//   name: "备货单",
		//   route: "/stocklist",
		// },
		{
			id: 4,
			name: "实用工具",
			route: "/utilities",
		},
	],
}

const mutations = {
	save: (state, data) => {
		state = Object.assign(state,data)
	}
}

const actions = {
	
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}