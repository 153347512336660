<template>
  <div class="right-tips">
    <div class="tips-item">
      <i class="iconfont icon-tixing"></i>
    </div>
    <div class="tips-item">
      <i class="iconfont icon-jilu"></i>
    </div>
    <div class="tips-item">
      <i class="iconfont icon-wenhao"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightTips",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.right-tips {
  position: fixed;
  top: 300px;
  right: 0;
  height: 90px;
  width: 30px;
  background-color: #409EFF;
  .tips-item {
    color: #fff;
    width: 100%;
    height: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
/deep/ .iconfont {
  font-size: 24px;
}
</style>