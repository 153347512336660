import {
	getConfig,
	editAddressExpress,
	editAddressInvoice,
	getAddressExpress,
	getAddressInvoice,
	editConfig,
	getAuthorUrl,
	getShopInfo
} from '@/api/setting.js'

const state = {
	express_list: [{
		id: '',
		shop_id: '',
		name: "",//发件人姓名
		phone: "",//电话
		
	}],//快递单发件人信息
	invoice_list: {
		id: '',
		shop_id: '',
		title: "",//发货标题
		operator: "",//经办人
		shop_name: "",//店铺名称
		phone: "",//电话
		tips: ""//友情提示
	}, //发货单发件人信息
	config_info: {
		order_sort: 4,
		express_sort: 1,
		invoice_sort: 1,
	},
	get_express_list: {},//获取快递单发件人列表
	get_invoice_list: {}, //发货单发件人列表
	get_shop_info: {
		data:{}
	}, //获取当前店铺信息
}

const mutations = {
	EXPRESS_LIST: (state, params) => {
		state.express_list = params;

	},
	INVOICE_LIST: (state, params) => {
		state.invoice_list = params;
	},
	CONFIG_INFO: (state, params) => {
		state.config_info = params;
	},
	GET_EXPRESS_LIST: (state, params) => {
		state.get_express_list = params;
	},
	GET_INVOICE_LIST: (state, params) => {
		state.get_invoice_list = params;
	},
	GET_SHOP_INFO: (state, params) => {
		state.get_shop_info = params;
	},
}

const actions = {
	// 获取基础设置
	getConfig({ commit }, params) {
		return new Promise((resolve, reject) => {
			getConfig(params).then(res => {
				if (res.code == 0) {
					var data = res.data;
					if (data.express_list.length > 0) {
						commit("EXPRESS_LIST", data.express_list);
					}
					if (data.invoice_list.length > 0) {
						commit("INVOICE_LIST", data.invoice_list[0]);
					}
					commit("CONFIG_INFO", data.config_info);
				}
				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 新增、编辑快递单发件人信息
	editAddressExpress({ }, params) {
		return new Promise((resolve, reject) => {
			editAddressExpress(params).then(res => {

				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 新增、编辑发货单发件人信息
	editAddressInvoice({ }, params) {
		return new Promise((resolve, reject) => {
			editAddressInvoice(params).then(res => {

				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 快递单发件人列表
	getAddressExpress({ }, params) {
		return new Promise((resolve, reject) => {
			getAddressExpress(params).then(res => {

				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 发货单发件人列表接口
	getAddressInvoice({ }, params) {
		return new Promise((resolve, reject) => {
			getAddressInvoice(params).then(res => {

				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 获取授权链接
	getAuthorUrl({ }, params) {
		return new Promise((resolve, reject) => {
			getAuthorUrl(params).then(res => {

				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 获取当前店铺信息
	getShopInfo({ commit }, params) {
		return new Promise((resolve, reject) => {
			getShopInfo(params).then(res => {
				commit("GET_SHOP_INFO", res);
				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 获取当前店铺信息
	editConfig({ commit }, params) {
		return new Promise((resolve, reject) => {
			editConfig(params).then(res => {
				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}