<template>
  <div class="appDialog">
    <!-- 提示安装打印控件 -->
    <el-dialog
      :visible="true"
      width="30%"
      background-color='#fff'
      :show-close = 'false'
      center>
      <div class="contentClass3">
        <div>
          <i class="el-icon-warning"></i>
          <span>您未安装打印控件</span>
          <p style="margin-top:15px;width:100%">安装完成请<span class="refreshBtn" @click="refreshBtn">刷新页面</span>或重启浏览器</p>
        </div>
        <div class="contentFooter">
          <div @click="comfirBtn">立即下载</div>
          <div @click="closePopup">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "appDialog",
  data() {
    return {
    };
  },
  methods: {
    comfirBtn() {
      window.open('/打印控件.zip')
    },
    refreshBtn() {
      location.reload()
    },
    closePopup(){
      this.$emit("closePopup")
    }

  },
};
</script>

<style lang="less" scoped>
.appDialog {
  /deep/ .el-dialog {
    background-color: #fff;
  }
  /deep/ .el-dialog__header {
      padding: 0;
  }
  /deep/ .el-dialog__body {
      padding: 0;
  }
  /deep/ .el-dialog__close{
      font-size: 20px;
      font-weight: 600;
      color: #2c2b3b;
  }
  /deep/ .el-dialog__close:hover {
      color: #2c2b3b;
  }
  .contentClass3 {
    color: #000;
    padding: 15px;
    .refreshBtn {
      font-size:14px;
      line-height:14px;
      color:#3366cc;
      margin:0;
      text-decoration:underline;
      cursor: pointer;
    }
    i {
      font-size: 26px;
      line-height: 26px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #ff7614;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-left: 36px;
      }
    }
    .contentFooter {
      width: 100%;
      text-align: right;
      margin-top: 40px;
      div {
        background-color: #ff7614;
        padding: 5px 18px;
        display: inline-block;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #fff;
        border: 1px  solid #b4b4b4;
        color: #333;
        margin-left: 10px;
      }
    }
  }
}
</style>