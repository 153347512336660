import request from '../plugins/axios.js'

// 登录授权接口

// 获取授权链接
export function getAuthorUrl() {
  return request(
    'post',
    '/Login/getAuthorUrl'
  )
}

// 获取token
export function getToken( params ) {
    return request(
      'post',
      '/Login/Login',
      params
    )
}