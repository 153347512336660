import { getGoodsList, getCatlist, editGoods } from '@/api/introduct.js'
import { Message } from 'element-ui'

// 宝贝简称

const state = {
	loadInfo: [], //分页等信息
	goods_list: [], //商品列表
	cat_list: [], //商品分类
	editGoodstype: []//简称
}

const mutations = {
	LOADINFO: (state, params) => {
		state.loadInfo = params;
	},
	GOODS_LIST: (state, params) => {
		state.goods_list = params;

	},
	CAT_LIST: (state, params) => {
		state.cat_list = params;
	},
	EDITGOODSTYPE: (state, params) => {
		state.editGoodstype = params;
	}
}

const actions = {
	// 获取/商品列表
	getGoodsList({ commit }, params) {
		return new Promise((resolve, reject) => {
			getGoodsList(params).then(res => {
				var data = res.data;
				var load_info = {
					total: data.total,
					per_page: data.per_page,
					current_page: data.current_page,
					last_page: data.last_page,
				};
				commit("LOADINFO", load_info);
				commit("GOODS_LIST", data.data);
				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 商品分类
	getCatlist({ commit }) {
		return new Promise((resolve, reject) => {
			getCatlist().then(res => {
				commit("CAT_LIST", res.data);
				resolve();
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 修改简称
	editGoods({ commit }, params) {
		var setParams = {
			data: params
		}
		return new Promise((resolve, reject) => {
			editGoods(setParams).then(res => {
				commit("EDITGOODSTYPE", res.data);
				if (res.code == 0) {
					Message.success(res.message);
				} else {
					Message.error(res.message);
				}
				resolve(res);
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}