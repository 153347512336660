import request from '../plugins/axios.js'

// 实用工具

// 打印记录查询
export function getPrintRecordList( params ) {
    return request(
      'post',
      '/PrintRecord/index',
      params
    )
}
// 发货记录查询
export function getDeliveryRecord( params ) {
  return request(
    'post',
    '/DeliveryRecord/index',
    params
  )
}
// 售后订单查询
export function getRefundList( params={} ) {
  params["NO_SHOW_LOADING"] = true
  return request(
    'post',
    '/PrintSearch/PrintSearchNew',
    params
  )
}