import request from '../plugins/axios.js'

// 商品列表接口
export function getGoodsList(data) {
    return request(
        'post',
        '/Goods/getGoodsList',
        data,
    )
}

// 商品分类
export function getCatlist() {
    return request(
        'post',
        '/Goods/getCatlist',
    )
}

// 编辑商品简称
export function editGoods(data) {
    return request(
        'post',
        '/Goods/editGoods',
        data,
    )
}