import { getSearchResult } from '@/api/search.js'
import { getExpressList } from '@/api/print.js'
import { OpDeliverySendOrder, set_default_branch, updateSortTemplate, addOrderRemark, updateFahuoState, batchUpdateExpressState, WaybillSearch, TaobaoAuthURL, cancelAuth, updateHang, } from '@/api/batchprint.js'


// 批量打印Module

const state = {
	// 标识当前显示哪个组件
	isShow: 'index',
	// 是否首页数据列表的缺省
	dataType: false,
	// 选择的快递模板
	expressTemplate: '0',
	is_shipped:0,
	// 查询表单数据
	searchFormData: {
		// 查询按什么时间
		Time: "1",
		// 开始时间
		startTime: '',
		// 结束时间
		endTime: '',
		// 订单状态
		status: '',
		// 按什么查询
		ww_baby_count_money: '',
		// 订单类型
		trade_type: '',
		// 预售类型
		presale_type: '',
		// 打印状态
		print_state: '',
		// 退款状态
		refund_state: '',
		// 留言备注
		remark_type: '1',
		// 买家旺旺
		wangwang: '',
		// 发货时效
		delivery_limit_type: '',
		// 商品名称
		productName: '',
		// 页码
		pageNum: '1',
		// 每页个数
		limit: '50'
	},
	// 查询结果数据
	resultList: {
		total: 0,
		buyer_count: '',
		current_page: 0,
		per_page: 0,
	},
	// 查询loading参数
	resultLoading: false,
	// 隐藏显示分页组件参数
	pageHide: true,
	// 备注参数
	remarkInfo: {
		orderId: '',
		memo: '',
		remarkIcon: ''
	},
	// 批量打印相关参数
	// 批量打印时要打印的订单信息
	printOrderInfo: {},
	// 批量打印时要打印的快递模板
	printExpressTemplate: '',
	printExpressBgimg: '',
	// 批量打印时要打印的发货模板
	printFahuoTemplate: '',
	printFahuoBgimg: '',

	searchOption: {
		// 是否拉取新数据
		pull_data: 0,
		// 查询按什么时间
		Time: '',
		Times: '',
		// 开始时间
		startTime: '',
		// 结束时间
		endTime: '',
		// 订单状态
		status: '',
		// 按什么查询
		ww_baby_count_money: '',
		// 订单类型
		trade_type: '',
		// 打印状态
		print_state: '',
		// 退款状态
		refund_state: '',
		// 留言备注
		remark_type: '1',
		// 买家旺旺
		wangwang: '',
		// 商品名称
		productName: '',
		// 页码
		pageNum: '1',
		// 每页个数
		limit: '20',
	},
	// 备货单搜索结果
	get_stocklist: {
		name: '',
		quantity: '',
		price: '',
		total_amount: '',
		product_img_url: '',
		buyer_feedback: '',
	},
	// 备货单合计
	summary: {
		sum: '',
		sum_price: ''
	},
	stocklistInfo: {},
	progressBarPopup: false,
	WaybillSite: [], //快递站点信息
	TaobaoAuthURL: "", // 淘宝授权链接
	dialogExpressVisible:false,// 快递单设置弹窗
	dialogFahuoVisible:false,// 发货单设置弹窗

	// 打印发货单弹窗
	dialogFahuoConsignerVisible:false,
	// 打印快递单弹窗
	dialogExpressConsignerVisible:false,
}

const mutations = {
	SAVE(state,data){
		state = Object.assign(state,data)
	},
	SET_EXPRESS_TEMPLATE: (state, data) => {
		state.expressTemplate = data
	},
	// 修改结果表
	SET_RESULT_LIST: (state, data) => {
		state.resultList = data
	},
	// 修改is_show
	SET_ISSHOW: (state, data) => {
		state.isShow = data
	},
	// 修改dataType
	SET_DATATYPE: (state, data) => {
		state.dataType = data
	},
	// 修改page_num
	SET_PAGENUM: (state, data) => {
		state.searchFormData.pageNum = data
	},
	// 修改limit
	SET_LISTLIMIT: (state, data) => {
		state.searchFormData.limit = data
	},
	// 修改page_hide
	SET_PAGEHIDE: (state, data) => {
		state.pageHide = data
	},
	// 修改remarkInfo
	SET_REMARK_ORDERID: (state, data) => {
		state.remarkInfo.orderId = data
	},
	SET_REMARK_MEMO: (state, data) => {
		state.remarkInfo.memo = data
	},
	SET_REMARK_REMARKICON: (state, data) => {
		state.remarkInfo.remarkIcon = data
	},
	// 修改加载参数
	SET_RESULT_LOADING: (state, data) => {
		state.resultLoading = data
	},
	// 修改打印相关信息
	SET_PRINT_ORDER_INFO: (state, data) => {
		state.printOrderInfo = data
	},
	SET_PRINT_EXPRESS_TEMPLATE: (state, data) => {
		state.printExpressTemplate = data
	},
	SET_PRINT_EXPRESS_BGIMG: (state, data) => {
		state.printExpressBgimg = data
	},
	SET_PRINT_FAHUO_TEMPLATE: (state, data) => {
		state.printFahuoTemplate = data
	},
	SET_PRINT_FAHUO_BGIMG: (state, data) => {
		state.printFahuoBgimg = data
	},

	// 备货单数据
	GET_STOCKLIST: (state, data) => {
		state.get_stocklist = data
	},
	// 备货单结果信息
	STOCKLISTINFO: (state, data) => {
		state.stocklistInfo = data
	},
	// 修改page_num
	SET_STOCKPAGENUM: (state, data) => {
		state.searchOption.pageNum = data
	},
	SET_SUMMARY: (state, data) => {
		state.summary = data
	},
	SET_PROGRESS_BAR_POPUP:(state, data)=>{
		state.progressBarPopup = data
	},
}

const actions = {
	// 获取查询结果
	get_search_result({ commit, state },no_params) {
		if(!state.progressBarPopup){
			commit('SET_RESULT_LOADING', true);
		}
		var params = JSON.parse(JSON.stringify(state.searchFormData))
		params.is_shipped=state.is_shipped
		params.endTime = params.startTime[1];
		params.startTime = params.startTime[0];
		no_params && (params=undefined) // 去掉查询参数
		return new Promise((resolve, reject) => {
			let query_state = true // 通过查询中状态控制轮询间隔
			query_fn()
			let query_timer = setInterval(query_fn,5000)
			// 定义查询
			async function query_fn (){
				if(query_state){
					query_state = false
					let res = await getSearchResult(params)
					if (res.data.last_page != '1') {
						commit('SET_PAGEHIDE', false)
					}
					if(res.data.task_progress || res.data.task_progress == 0){
						query_state = true
						commit('SET_PROGRESS_BAR_POPUP', res.data.task_progress)
					}else{
						commit('SET_PROGRESS_BAR_POPUP', false)
						for(let i in res.data.data) {
							res.data.data[i].checkedGoods = res.data.data[i].order_sub
						}
						commit('SET_RESULT_LIST', res.data)
						clearInterval(query_timer)
						commit('SET_RESULT_LOADING', false);
						resolve(res)
					}
				}
			}
		})
	},
	// 添加备注信息
	add_order_remark({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			addOrderRemark(state.remarkInfo).then(res => {
				commit('SET_REMARK_ORDERID', '')
				commit('SET_REMARK_MEMO', '')
				commit('SET_REMARK_REMARKICON', 1)
				setTimeout(() => {
					dispatch('get_search_result')
				}, 2000);
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 批量更新快递状态
	batch_update_express_state({ commit, state }) {
		return new Promise((resolve, reject) => {
			batchUpdateExpressState({ id: 1 }).then(res => {

				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 批量更新发货状态
	update_fahuo_state({ }, params) {
		return new Promise((resolve, reject) => {
			updateFahuoState(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 批量更新快递状态
	update_express_state({ }, params) {
		return new Promise((resolve, reject) => {
			batchUpdateExpressState(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取备货单查询结果
	get_stocklist_result({ commit, state }) {
		// commit('SET_RESULT_LOADING', true);
		var params = JSON.parse(JSON.stringify(state.searchOption))
		params.endTime = params.Times[1];
		params.startTime = params.Times[0];
		return new Promise((resolve, reject) => {
			getSearchResult(params).then(res => {
				var data = res.data;
				var load_info = {
					total: data.total,
					per_page: data.per_page,
					current_page: data.current_page,
					last_page: data.last_page,
				};
				var sum = 0;
				var sum_price = 0.0;
				var searchDate = {};
				searchDate = res.data.data;
				if (res.data.length != 0) {
					var len = searchDate.length;//this.data.length;
					for (var i = 0; i < len; i++) {
						if (searchDate[i].quantity != null) {
							sum += searchDate[i].quantity;//订单总数量
							sum_price += Number(searchDate[i].total_amount);//price 应付总金额
						} else {
							continue;
						}
					}
				}
				sum_price = sum_price.toFixed(2);
				var sumdata = {
					sum: sum,
					sum_price: sum_price
				}

				commit('STOCKLISTINFO', load_info);
				commit('GET_STOCKLIST', searchDate);
				commit('SET_SUMMARY', sumdata);
				// commit('SET_RESULT_LOADING', false);
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取网点信息
	GetWaybillSearch({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			WaybillSearch(params).then(res => {
				commit("SAVE",{WaybillSite:res.data })
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// TaobaoAuthURL: "", // 淘宝授权链接
	GetTaobaoAuthURL({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			TaobaoAuthURL(params).then(res => {
				commit("SAVE",{TaobaoAuthURL:res.data})
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	CancelAuth({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			cancelAuth(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	UpdateHang({ commit, state }, params){
		return new Promise((resolve, reject) => {
			updateHang(params).then(res => {
				if(res.code==0){
					let resultList = JSON.parse(JSON.stringify( state.resultList))
					resultList.data.map((item,index)=>{
						if(params.transaction_id.includes(item.transaction_id)){
							item.is_hang = params.is_hang
						}
					})
					commit("SET_RESULT_LIST",resultList)
				}else{
					this.$message({ message: res.message, type: "warning" });
				}
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	UpdateSortTemplate({ commit, state }, params){
		return new Promise((resolve, reject) => {
			updateSortTemplate(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	
	set_default_branch({ commit, state }, params){
		return new Promise((resolve, reject) => {
			set_default_branch(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},

	OpDeliverySendOrder({ commit, state },params){
		return new Promise((resolve, reject) => {
			OpDeliverySendOrder(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
		
	}
	
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}